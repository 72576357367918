import React from 'react'
import { withLocalize } from "react-localize-redux";
import connect from 'react-redux/lib/connect/connect';
import { bindActionCreators } from 'redux';
import { isNullOrUndefined } from '../../../store/storeFunctions';

import { appContextActions } from '../../../store/appContextStore'
import { parseQuery, isObjectPathDefined } from '../../../store/apiWrapper';
import Loading from '../../common/loading/loading'
import ReportLayout from '../../layoutItems/reportLayout';
import ModalWindow from '../../common/modal/modalWindow';
import Message from '../../common/message/message';
import {COUNTRY_TYPES} from '../../common/enums'

class MunicipalityResultComponent extends React.Component {
    state = { showModal: false, name: "", ico: "" }
    componentDidMount() {
        const q = parseQuery(window.location.search);
        this.props.getMunicipality(q.name, q.ico, q.nutsCode, q.country);
    }

    render() {
        const q = parseQuery(window.location.search);
        return (<div>
            {!this.props.report.isLoading &&
                <ReportLayout>
                    {this.renderError(this.props.searchMunicip)}
                    {isObjectPathDefined(this.props, 'searchMunicip.searchResult.data') && this.renderResult(this.props.searchMunicip, q.country, q.monitored)}
                    {isObjectPathDefined(this.props, 'searchMunicip') && this.props.searchMunicip.isSearching && this.renderLoading()}
                </ReportLayout>
            }
            {this.props.report.isLoading && this.renderLoading()}
        </div>
        )
    }

    renderLoading = () => {
        return (
            <Loading />
        )
    }

    showHideModal = () => {
        this.setState({ showModal: (this.state.showModal === false) });
    }

    renderError = (sr) => {
        if (!isNullOrUndefined(sr) && !isNullOrUndefined(sr.error)) {
            return (<Message error={sr.error.data.error}/>);
        }
        else if (!isNullOrUndefined(sr) && !isNullOrUndefined(sr.searchResult) && !isNullOrUndefined(sr.searchResult.error)) {
            return (<Message error={sr.searchResult.error}/>);
        }
    }

    renderResult = (res, country, monitored) => {
        if (!isNullOrUndefined(res) && (!isNullOrUndefined(res.error) || (!isNullOrUndefined(res.searchResult) && !isNullOrUndefined(res.searchResult.error)))) {
            return null;
        }
        return (
            <div className="box table-container">
                <ModalWindow isActive={this.state.showModal} title='IRating.Report.ConfirmInfo.Title'
                    onClickCancel={() => this.showHideModal()} cancelButtonName='IRating.Common.Button.Cancel'
                    successButton={true} successButtonName='IRating.Report.Confirm' onClickSuccess={() => this.createReport(country, monitored)}>
                    <p>{this.props.translate('IRating.Report.ConfirmInfo') + " '" + this.state.name + "'?"}</p>
                </ModalWindow>

                <table className="table is-striped is-narrow is-hoverable is-fullwidth">
                    <tbody>
                        <tr>
                            <th>
                                {this.props.translate('IRating.Report.Search.Ico')}
                            </th>
                            <th>
                                {this.props.translate('IRating.Report.Search.Name')}
                            </th>
                            <th>
                                {this.props.translate('IRating.Report.Search.DistrictCode')}
                            </th>
                            <th>
                                {this.props.translate('IRating.Report.Search.District')}
                            </th>
                            {country === COUNTRY_TYPES.CZ &&
                                <React.Fragment>
                                    <th>
                                        {this.props.translate('IRating.Report.Search.Address')}
                                    </th>
                                    <th>
                                        {this.props.translate('IRating.Report.Search.PostCode')}
                                    </th>
                                </React.Fragment>
                            }
                            <th>
                            </th>
                        </tr>
                        {res.searchResult.data.map((r, i) =>

                            <tr key={i}>
                                <td>
                                    {r.ico}
                                </td>
                                <td>
                                    {r.name}
                                </td>
                                <td>
                                    {r.nutsCode}
                                </td>
                                <td>
                                    {r.nutsName}
                                </td>
                                {country === COUNTRY_TYPES.CZ &&
                                    <React.Fragment>
                                        <td>
                                            {r.address}
                                        </td>
                                        <td>
                                            {r.postCode}
                                        </td>
                                    </React.Fragment>
                                }
                                <td>
                                    <button className="button" onClick={() => this.setModal(r.name, r.ico)}>{this.props.translate('IRating.Report.Confirm')}</button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

        );
    }
    setModal = (name, ico) => {
        this.setState({ name: name, ico: ico });
        this.showHideModal()
    }

    createReport = (country, monitored) => {
        this.showHideModal();
        this.props.createReport('municipality', { subject: { ico: this.state.ico, name: this.state.name } }, country, monitored);
    }
}

export default withLocalize(connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
    )(MunicipalityResultComponent));