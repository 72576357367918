import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { appContextActions } from '../../store/appContextStore'
import { withRouter } from "react-router";
import classnames from 'classnames'
import Logo from '../../img/irating.gif';
import LoginComponent from '../loginComponent';
import { isOnPath, hasPermission } from '../../store/apiWrapper'
import { withLocalize } from "react-localize-redux";

class NavMenu extends Component {
    state = { isBurgerActive: false }

    toggleNavbar() {
        if (this.state.isBurgerActive)
            this.setState({ isBurgerActive: false })
        else
            this.setState({ isBurgerActive: true })
    }

    render() {
        return (
            <nav className="navbar has-shadow">
                <div className="container">
                    <div className="navbar-brand">
                        <Link to="/" className="navbar-item"><img id="logo" alt="" src={Logo} /></Link>
                        <a role="button" className={classnames({ "is-active": this.state.isBurgerActive, "navbar-burger": true })} aria-label="menu" aria-expanded="false" data-target="ass" onClick={(event) => this.toggleNavbar()}>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div>
                    <div id="ass" className={classnames({ "is-active": this.state.isBurgerActive, "navbar-menu": true })}>
                        {this.props.authentication.isAuthorized &&
                            <div className="navbar-start">
                                {(hasPermission(null, "UserRatingMSP") || hasPermission(null, "UserRatingCalc")) && <Link className={classnames({ "is-active": isOnPath({ prefix: "/report" }), "navbar-item": true })} to="/report">{this.props.translate('IRating.Ratings')}</Link>}
                                {true && <Link className={classnames({ "is-active": isOnPath({ prefix: "/history" }), "navbar-item": true })} to="/history">{this.props.translate('IRating.History')}</Link>}
                                {hasPermission(null, "UserCCB") && <Link className={classnames({ "is-active": isOnPath({ prefix: "/approvals" }), "navbar-item": true })} to="/approvals">{this.props.translate('IRating.Approvals')}</Link>}
                                {this.props.authentication.isGeneralUser && <Link className={classnames({ "is-active": isOnPath({ prefix: "/offer" }), "navbar-item": true })} to="/offer">{this.props.translate('IRating.Offers')}</Link>}
                            </div>
                        }
                        <div className={classnames({ "is-active": isOnPath({ prefix: "/settings" }), "navbar-end" : true })}><LoginComponent navbar={true} /></div>
                    </div>
                </div>
            </nav>
        );
    }
}

NavMenu.propTypes = {
}

export default withLocalize(connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
)(withRouter(NavMenu)));