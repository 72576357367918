import React from 'react'
import { withLocalize } from "react-localize-redux";
import connect from 'react-redux/lib/connect/connect';
import { isNullOrUndefined } from '../../store/storeFunctions';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from "react-router";

import { getLanguage, getAuthorization } from '../../store/apiWrapper';
import { appContextActions } from '../../store/appContextStore'
import Loading from '../common/loading/loading';
import Message from '../common/message/message';

class OfferDetailComponent extends React.Component {
    state = { name: "" }
    componentDidMount() {
        const { offerName } = this.props.match.params;
        if (!isNullOrUndefined(offerName))
            this.props.getOffer(offerName);
    }


    render() {
        return (
            <div className="columns work-container is-desktop">
                <div className="column left-navbar-container">
                </div>
                <div className="column right-body-container is-three-quarters">
                    {this.renderError(this.props.offer)}
                    {this.renderResult(this.props.offer)}
                    {this.renderLoading()}
                </div>
                <div className="column">
                </div>
            </div>
        )
    }

    renderLoading = () => {
        if (!this.props.offer.isLoading)
            return null;
        return (<Loading />)
    }

    renderError = (sr) => {
        if (!isNullOrUndefined(sr) && !isNullOrUndefined(sr.error)) {
            return (<Message error={sr.error.data.error} />);
        }
        else if (!isNullOrUndefined(sr) && !isNullOrUndefined(sr.offerResult) && !isNullOrUndefined(sr.offerResult.error)) {
            return (<Message error={sr.offerResult.error} />);
        }
    }

    renderField = (label, body) => {
        return (
            <div className="field is-horizontal">
                    <div className="field-label">
                        <label className="label">{this.props.translate(`IRating.Offer.${label}`)}</label>
                    </div>
                    <div className="field-body">
                        {body}
                    </div>
                </div>
        )
    }

    getNote = (date, updates) => {
        var note = ``;
        if(!isNullOrUndefined(date))
        note += `${this.props.translate(`IRating.Offer.CreationDate`)}: ${moment(date).format('L')} ${moment(date).format('LT')}`;
        if(!isNullOrUndefined(updates))
        updates.forEach(element => {
            note += ` | ${this.props.translate(`IRating.Offer.Update`)}: ${moment(element).format('L')} ${moment(element).format('LT')}`
        });
        return note;
    }

    renderResult = (res) => {
        if (!isNullOrUndefined(res) && (!isNullOrUndefined(res.error) || (!isNullOrUndefined(res.offerResult) && !isNullOrUndefined(res.offerResult.error)))) {
            return null;
        }
        if (isNullOrUndefined(res) || isNullOrUndefined(res.offerResult) || isNullOrUndefined(res.offerResult.data))
            return null;
        let data = res.offerResult.data;
        return (
            <div className="box table-container">
                {this.renderField("Name", <p>{data.name}</p>)}
                {this.renderField("ForRatings", <p>{data.rating}</p>)}
                {this.renderField("Active", <p>{(data.valid ? <FontAwesomeIcon icon="check" /> : <FontAwesomeIcon icon="times" />)}</p>)}
                {this.renderField("Note", <p>{this.getNote(data.creationDate, data.updates)}</p>)}
                {this.renderField("Message", <p>{data.message}</p>)}
                {this.renderField("Partner", <p>{data.partner}</p>)}
                {this.renderField("Url", <a href={data.url} target="_blank">{data.url}</a>)}
                {data.isFile && this.renderField("Attachment", <a className="button is-small" target="_blank" href={`api/offer/${data.name}/download?lang=${getLanguage()}&access_token=${getAuthorization(false)}`}>{this.props.translate('IRating.Common.Button.Download')}</a>)}
            </div>

        );
    }
}

export default withLocalize(connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
)(OfferDetailComponent));