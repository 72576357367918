import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withLocalize } from "react-localize-redux";
import { isNullOrUndefined } from '../store/storeFunctions';
import Moment from 'moment'
import { MultiSelect } from 'react-sm-select';

import { appContextActions } from '../store/appContextStore';
import { setLanguage, hasPermission, getMomentLanguage } from '../store/apiWrapper';
import FieldHorizontal from './common/formFields/fieldHorizontal';
import Loading from './common/loading/loading';
import InputControl from './common/formFields/inputControl';

class SettingsComponent extends React.Component {
    state = this.getUserData();

    getUserData() {
        var user = this.props.authentication.profile;
        var langCode = this.getLanguage(this.props);
        return { userName: user.username, name: user.name, surName: user.surname, email: user.email, language: langCode === "" ? [] : [langCode], errors: {} };
    }
    componentDidMount() {
        this.props.resetSettings();
    }

    getLanguage(props) {
        var lang = props.languages.find((element) => {
            return element.active === true;
        });
        var langCode = "";
        if (!isNullOrUndefined(lang)) {
            langCode = lang.code;
        }
        return langCode;
    }

    static getDerivedStateFromProps(props, state) {
        var lang = props.languages.find((element) => {
            return element.active === true;
        });
        if (!isNullOrUndefined(lang) && state.language.length === 0) {
            return { language: [lang.code] };
        }
        return null;
    }

    changeEvent = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    changeLanguage(lang) {
        Moment.locale(getMomentLanguage(lang) || 'sk');
        this.props.fetchTranslations(lang, this.props.addTranslationForLanguage, true);
        setLanguage(lang)
    }

    handleSaveSubmit = (event) => {
        let errors = {};
        if (hasPermission(null, "UserCCB") || hasPermission(null, "UserRatingMSP") || hasPermission(null, "UserRatingCalc") || hasPermission(null, "PartnerView")) {
            if (isNullOrUndefined(this.state.surName) || this.state.surName.length < 1) {
                errors.surName = this.props.translate('Common.RequiredField');
            }
            if (isNullOrUndefined(this.state.name) || this.state.name.length < 1) {
                errors.name = this.props.translate('Common.RequiredField');
            }
        }
        if (hasPermission(null, "UserRatingMSP")) {
            if (isNullOrUndefined(this.state.email) || this.state.email.length < 1) {
                errors.email = this.props.translate('Common.RequiredField');
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email)) {
                errors.email = this.props.translate('Common.InvalidEmail');
            }
        }
        this.setState({ errors: errors });
        event.preventDefault();
        if (Object.keys(errors).length === 0) {
            this.props.updateUser(this.props.authentication.profile.id, this.state.email, this.state.name, this.state.surName, this.state.language[0]);
            this.changeLanguage(this.state.language[0]);
        }
    }

    render() {
        return (
            <div className="columns work-container">
                <div className="column left-navbar-container">
                </div>
                <div className="column right-body-container is-three-quarters">
                    <div className="container is-centered columns">
                        <div className="column is-6 form-layout">
                            {this.renderSettings(this.props.settings)}
                        </div>
                    </div>
                </div>
                <div className="column">
                </div>
                {this.renderLoading()}
            </div>
        )
    }

    renderLoading = () => {
        if (this.props.settings.isUpdating)
            return (<Loading />);

    }

    renderSettings() {
        return (

            <div className="box">
                <div className="has-text-centered">
                    <h1 className="title is-4">{this.props.translate('IRating.Settings.Title')}</h1>
                </div>
                <form onSubmit={(e) => this.handleSaveSubmit(e)}>
                    <div className="container">
                        <FieldHorizontal label={this.props.translate('IRating.Settings.UserName')}>
                            <InputControl type="text" fieldName="userName" disabled={true} value={this.state.userName} placeholder={this.props.translate('IRating.Settings.UserName')} onChange={this.changeEvent} />
                        </FieldHorizontal>
                        {(hasPermission(null, "UserCCB") || hasPermission(null, "UserRatingMSP") || hasPermission(null, "UserRatingCalc") || hasPermission(null, "PartnerView")) &&
                            <>
                                <FieldHorizontal label={this.props.translate('IRating.Settings.Name')}>
                                    <InputControl type="text" fieldName="name" value={this.state.name} error={this.state.errors.name} placeholder={this.props.translate('IRating.Settings.Name')} onChange={this.changeEvent} />
                                </FieldHorizontal>
                                <FieldHorizontal label={this.props.translate('IRating.Settings.SurName')}>
                                    <InputControl type="text" fieldName="surName" value={this.state.surName} error={this.state.errors.surName} placeholder={this.props.translate('IRating.Settings.SurName')} onChange={this.changeEvent} />
                                </FieldHorizontal>
                            </>
                        }
                        <FieldHorizontal label={this.props.translate('IRating.Settings.Email')}>
                            <InputControl type="email" fieldName="email" value={this.state.email} error={this.state.errors.email} placeholder={this.props.translate('IRating.Settings.Email')} onChange={this.changeEvent} />
                        </FieldHorizontal>
                        <FieldHorizontal label={this.props.translate('IRating.Settings.Language')}>
                            <MultiSelect mode="single" isLoading={this.props.langLoading} value={this.state.language} options={this.props.languages.map(x => { return { value: x.code, label: x.name } })}
                                onChange={language => this.setState({ language })} valuePlaceholder={this.props.translate(`Common.Select`)} />
                        </FieldHorizontal>
                        <div className="field is-horizontal">
                            <div className="field-label">
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <button className="button is-primary" type="submit">{this.props.translate('IRating.Common.Button.Save')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
export default withLocalize(connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
)(SettingsComponent));
