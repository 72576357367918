import React from 'react';
import { withLocalize } from "react-localize-redux";
import { connect } from 'react-redux';
import { parseQuery } from '../../store/apiWrapper';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { appContextActions } from '../../store/appContextStore'
import Logo from '../../img/iRating';

import "./ssoLoginComponent.scss"

class SsoLoginComponent extends React.Component {

    componentDidMount() {
        const q = parseQuery(window.location.search);
        this.props.loginSso(q.token);
    }

    render() {

        return (<>
            {this.props.authentication.isAuthorized === false && this.showError(this.props.authentication.errorMessage)}
            <div className="sso-logo">
                <div className="has-text-centered">
                    <h4 className="title is-3">{this.props.translate('Login.Redirecting')}</h4>
                    <h1 className="title is-1">{`${this.props.translate('Login.ToApp')} ${this.props.translate('NavBar.Irating')}`}</h1>
                    <br />
                    <Logo />
                </div>
            </div>
        </>
        );
    }
    showError = (error) => {
        if (error === null || error === undefined) {
            return null;
        }
        return (
            <div className="columns">
                <div className="column is-4 is-offset-4">
                    <div className="notification is-danger">{error}</div>
                </div>
            </div>
        );
    };
}

export default withLocalize(withRouter(connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
)(SsoLoginComponent)));