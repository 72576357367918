import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { withLocalize } from "react-localize-redux";
import { isNullOrUndefined } from '../../../store/storeFunctions';
import classnames from 'classnames'

import { appContextActions } from '../../../store/appContextStore'
import { isOnPath, hasPermission } from '../../../store/apiWrapper'

class LeftNavBar extends React.Component {

    render() {
        return (
            <aside className="is-narrow-mobile is-fullheight left-bar-layout">
                <p className="menu-label">
                    {this.props.translate('IRating.Report.MenuTitle')}
                </p>
                <ul className="menu-list">
                    {this.renderMenuItem("?type=sme&country=cz", 'smecz', "SmeCzRating")}
                    {this.renderMenuItem("?type=corp&country=cz", 'corpcz', "CorpCzRating")}
                    {this.renderMenuItem("?type=sme&country=sk", 'smesk', "SmeSkRating")}
                    {this.renderMenuItem("?type=nonProfitOrganization&country=cz", 'nonprofitorganizationcz', "NonProfitOrganizationCzRating")}
                    {this.renderMenuItem("?type=entrepreneur&country=cz", 'entrepreneurcz', "EntrepreneurCzRating")}
                    {this.renderMenuItem("?type=housingCooperative&country=cz", 'housingcooperativecz', "HousingCooperativeCzRating")}
                    {this.renderMenuItem("?type=municipality&country=cz", 'municipalitycz', "MunicipalityCzRating", /((\/|)report\/search((\/municipality.*country=cz)|(\?type=municipality&country=cz)))/i)}
                    {this.renderMenuItem("?type=municipality&country=sk", 'municipalitysk', "MunicipalitySkRating", /((\/|)report\/search((\/municipality.*country=sk)|(\?type=municipality&country=sk)))/i)}
                </ul>
            </aside>
        );
    }

    renderMenuItem = (link, translation, permission = null, regex = null) => {
        if (!isNullOrUndefined(permission) && hasPermission(null, permission)) {
            link = `/report/search${link}`;
            return (
                <li>
                    <Link className={classnames({ "is-active": translation === `${this.props.type}${this.props.country}`})} to={link}>
                        {this.props.translate(`IRating.Report.${translation}`)}
                    </Link>
                </li>
            )
        }
        return null;
    }
}

export default withLocalize(connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
)(LeftNavBar));
