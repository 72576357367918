import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withLocalize } from "react-localize-redux";

import { appContextActions } from '../store/appContextStore'
import { hasPermission } from '../store/apiWrapper'
import Redirect from 'react-router-dom/Redirect';

class HomeComponent extends React.Component {
    render() {
        if (!this.props.authentication.isAuthorized) {
            return null;
        }
        return (<>
            {(hasPermission(null, "UserRatingMSP") || hasPermission(null, "UserRatingCalc")) ? <Redirect to="/report"/>
            : <Redirect to="/history"/>}
            </>
        );
    }
}
export default withLocalize(connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
)(HomeComponent));
