import React from 'react'
import { withLocalize } from "react-localize-redux";
import connect from 'react-redux/lib/connect/connect';
import { bindActionCreators } from 'redux';
import { MultiSelect } from 'react-sm-select';
import PropTypes from 'prop-types';

import { hasPermission } from '../../store/apiWrapper';
import { appContextActions } from '../../store/appContextStore';
import FieldHorizontal from '../common/formFields/fieldHorizontal';

class ReportPermissionsFormComponent extends React.Component {

    render() {
        if (hasPermission(null, "UserRatingMSP") || hasPermission(null, "UserCCB")) {
            return (
                <>
                    {hasPermission(null, "UserRatingMSP") && <hr />}
                    <FieldHorizontal label={this.props.translate(`IRating.Report.Search.AllowedRating`)}>
                        <MultiSelect mode="single" isLoading={this.props.ratingRange.isLoading} value={this.props.publishAboveRatingValue} options={this.props.ratingRange.ratingRange.map(x => { return { value: x, label: x } })}
                            onChange={this.props.publishAboveRatingOnChange} allSelectedLabel={this.props.translate(`Search.Common.All`)} valuePlaceholder={this.props.translate(`Common.Select`)} />
                    </FieldHorizontal>
                    <FieldHorizontal label={this.props.translate(`IRating.Report.Search.Partners`)}>
                        <MultiSelect hasSelectAll isLoading={this.props.domains.isLoading} enableSearch resetable value={this.props.partnersValue} options={this.props.domains.partners.map(x => { return { value: x.code, label: x.name } })}
                            onChange={this.props.partnersOnChange} allSelectedLabel={this.props.translate(`Search.Common.All`)}
                            searchPlaceholder={this.props.translate(`Common.Search`)} selectAllLabel={this.props.translate(`Multiselect.CheckUncheckAll`)}
                            valuePlaceholder={this.props.translate(`Common.Select`)} />
                    </FieldHorizontal>
                </>
            );
        }
        else {
            return null;
        }
    }
}

ReportPermissionsFormComponent.propTypes = {
    publishAboveRatingValue: PropTypes.array.isRequired,
    publishAboveRatingOnChange: PropTypes.func.isRequired,
    partnersValue: PropTypes.array.isRequired,
    partnersOnChange: PropTypes.func.isRequired
}

export default withLocalize(connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
)(ReportPermissionsFormComponent));