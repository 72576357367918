import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appContextActions } from '../store/appContextStore';
import { withLocalize } from "react-localize-redux";
import withRouter from 'react-router/withRouter';

class LogoutComponent extends React.Component {
    componentDidMount = () => {
        if (this.props.authentication.isAuthorized) {
            this.props.logout(this.props.authentication.isGeneralUser ? '/?returnUrl=login-user' : '/');
        } else {
            this.props.redirectBack('/login');
        }
    }

    render() {
        return "logout in progress"
    }
}
export default withLocalize(withRouter(connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
)(LogoutComponent)));
