import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appContextActions } from '../store/appContextStore'
import { withLocalize, Translate } from "react-localize-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, withRouter } from 'react-router-dom';
import { isOnPath } from '../store/apiWrapper'
import classnames from 'classnames';
import { apiPost } from '../store/apiWrapper';
import UserIcon from '../img/User';
import NavbarButton from "./layoutItems/navbarButton";

class LoginComponent extends React.Component {
    state = { username: this.props.isChangePwd ? this.props.authentication.profile.username : '', pswd: '', newPswd: '', newPswd2: '', error: false, message: undefined, isChangePwd: this.props.isChangePwd };

    componentDidMount = () => {
        if (this.props.authentication.isAuthorized) {
            if (!this.props.navbar && !this.state.isChangePwd) { 
                this.props.redirectBack(window.location.href); 
            }
        }
    }

    handleSubmit = (event, type) => {
        event.preventDefault();
        if (type === 'login') {
            this.props.login(this.state.username, this.state.pswd, window.location.href);
            return;
        }
        if (type === 'change') {
            let model = { username: this.state.username, oldPswd: this.state.pswd, newPswd: this.state.newPswd };
            this.setState({ loading: true, error: false, message: undefined, pswd: '', newPswd: '', newPswd2: '' });
            apiPost('api/account/change-pwd', undefined, model,
                (res) => {
                    if (!res.data.success) {
                        this.setState({ error: true, message: res.data.errorMessage });
                    } else {
                        this.props.login(this.state.username, model.newPswd, window.location.href);
                    }
                },
                (err) => this.setState({ error: true, message: err }),
                () => this.setState({ loading: false, completed: true })
            );
            return;
        }
    }

    changeEvent = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    showError = (error) => {
        if (error === null || error === undefined) {
            return "";
        }
        return (
            <div className="columns">
                <div className="column is-4 is-offset-4">
                    <div className="notification is-danger">{error}</div>
                </div>
            </div>
        );
    };

    renderAsNavbar = () => {
        if (this.props.authentication.isAuthorized) {
            return (
                <div className="navbar-item" style={{ display: "flex", flexDirection: "column", padding: ".25em" }}>
                    <NavbarButton isRightOnSmallerDesktop
                        link={<>
                            <div className='navmenu-svg-wrapper'>
                                <UserIcon className="icon-centered navmenu-svg svg-standard-fill" />
                            </div>
                            <span style={{ paddingLeft: ".5em" }}>{this.props.authentication.profile.username}</span>
                        </>}
                        dropdown={<>

                            <a className='navbar-item' onClick={() => this.props.changePwd()}>
                                <Translate id="DashboardLayout.ChangePassword" />
                            </a>
                            <hr className="navbar-divider" style={{ marginBottom: "0", marginTop: "0" }}></hr>
                            <Link className={classnames("navbar-item")} to="/settings">
                                {this.props.translate('IRating.Settings')}
                            </Link>

                            <hr className="navbar-divider" style={{ marginBottom: "0", marginTop: "0" }}></hr>
                            <a className='navbar-item sign-out' onClick={() => { this.props.logout() }}>
                                <Translate id="DashboardLayout.SignOut" />
                            </a>
                        </>}
                        toggleMobileMenu={this.props.toggleAccountMobileMenu}
                        dropdownClassname='user-dropdown'
                        minWidth={true} />
                </div>
            );
        }
        if (isOnPath({ prefix: '/login' }) || isOnPath({ prefix: '/login-user' })) {
            return "";
        } else {
            return <Link to='/login' className="navbar-item"><FontAwesomeIcon icon="user" size="lg" style={{ marginRight: ".25em" }} />&nbsp;{this.props.translate('Login.Login')}</Link>;
        }
    }

    renderAsForm = () => {
        if (this.props.authentication.isAuthorized) {
            return "";
        }
        if (this.props.authentication.isAuthorized === false && this.props.authentication.errorMessage === 'ExpiredPassword') {
            return this.renderPwdChange();
        }

        return this.renderLoginForm();
    }

    isFormValid = () => {
        return this.fieldsFilled() && this.pswdMatch();
    }

    fieldsFilled = () => {
        return this.state.username !== '' && this.state.pswd !== '' && this.state.newPswd !== '' && this.state.newPswd2 !== '';
    }

    pswdMatch = () => {
        return this.state.newPswd === this.state.newPswd2;
    }

    renderPwdChange = () => {
        return (
            <section className="section">
                <form onSubmit={(e) => this.handleSubmit(e, 'change')}>
                    <div className="columns">
                        <div className="column is-4 is-offset-4">
                            <h1 className="subtitle is-4">{this.props.translate('PswdChange.FormCaption')}</h1>
                        </div>
                    </div>
                    {!this.state.isChangePwd || this.state.error  && <div className="columns">
                        <div className="column is-4 is-offset-4">
                            <div className={classnames({ "notification": true, "is-warning": !this.state.error, "is-danger": this.state.error })}>{!this.state.error ? this.props.translate('Login.PasswordExpired') : this.state.message}</div>
                        </div>
                    </div>}
                    <div className="columns">
                        <div className="column is-4 is-offset-4">
                            <div className="field">
                                <p className="control has-icons-left has-icons-right">
                                    <input className="input" type="text" placeholder={this.props.translate('Login.Username')} disabled={true} name='username' value={this.state.username} onChange={this.changeEvent} />
                                    <span className="icon is-small is-left"><FontAwesomeIcon icon="user" /></span>
                                </p>
                            </div>
                            <div className="field">
                                <p className="control has-icons-left">
                                    <input className="input" type="password" placeholder={this.props.translate('PswdChange.OldPassword')} disabled={this.props.loading} name="pswd" value={this.state.pswd} onChange={this.changeEvent} />
                                    <span className="icon is-small is-left"><FontAwesomeIcon icon="lock" /></span>
                                </p>
                            </div>
                            <div className="field">
                                <p className="control has-icons-left">
                                    <input className={classnames({ "input": true, "is-danger": this.fieldsFilled() && !this.pswdMatch() })} type="password" placeholder={this.props.translate('PswdChange.NewPassword')} disabled={this.state.loading} name="newPswd" value={this.state.newPswd} onChange={this.changeEvent} />
                                    <span className="icon is-small is-left"><FontAwesomeIcon icon="lock" /></span>
                                </p>
                            </div>
                            <div className="field">
                                <p className="control has-icons-left">
                                    <input className={classnames({ "input": true, "is-danger": this.fieldsFilled() && !this.pswdMatch() })} type="password" placeholder={this.props.translate('PswdChange.NewPassword')} disabled={this.state.loading} name="newPswd2" value={this.state.newPswd2} onChange={this.changeEvent} />
                                    <span className="icon is-small is-left"><FontAwesomeIcon icon="lock" /></span>
                                </p>
                                {this.fieldsFilled() && !this.pswdMatch() && <p className="help is-danger">{this.props.translate('PswdChange.PswdsDontMatch')}</p>}
                            </div>
                            <div className="field columns">
                                <div className="column">
                                    <p className="control">
                                        {!this.state.loading && (<input type="submit" value={this.props.translate('PswdChange.SubmitBtn')} className="is-success button is-pulled-right" disabled={this.state.loading || !this.isFormValid()} />)}
                                        {this.state.loading && (<span className="is-info button is-pulled-right"><FontAwesomeIcon icon="cog" spin /></span>)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        );
    }

    renderLoginForm = () => {
        return (
            <section className="section">
                <form onSubmit={(e) => this.handleSubmit(e, 'login')}>
                    <div className="columns">
                        <div className="column is-4 is-offset-4">
                            <h1 className="subtitle is-4">{this.props.translate('Login.FormCaption')}</h1>
                        </div>
                    </div>
                    {this.props.authentication.isAuthorized === false && this.showError(this.props.authentication.errorMessage)}
                    <div className="columns">
                        <div className="column is-4 is-offset-4">
                            <div className="field">
                                <p className="control has-icons-left has-icons-right">
                                    <input className="input" type="text" placeholder={this.props.translate('Login.Username')} disabled={this.props.loading} name='username' value={this.state.username} onChange={this.changeEvent} />
                                    <span className="icon is-small is-left"><FontAwesomeIcon icon="user" /></span>
                                </p>
                            </div>
                            <div className="field">
                                <p className="control has-icons-left">
                                    <input className="input" type="password" placeholder={this.props.translate('Login.Password')} disabled={this.props.loading} name="pswd" value={this.state.pwsd} onChange={this.changeEvent} />
                                    <span className="icon is-small is-left"><FontAwesomeIcon icon="lock" /></span>
                                </p>
                            </div>
                            <div className="field columns">
                                <div className="column">
                                    <Link to="/lost-password">{this.props.translate('Login.LostPassword')}</Link>
                                </div>
                                <div className="column">
                                    <p className="control">
                                        {!this.props.authentication.loading && (<input type="submit" value={this.props.translate('Login.Login')} className="is-success button is-pulled-right" disabled={this.props.loading} />)}
                                        {this.props.authentication.loading && (<span className="is-info button is-pulled-right"><FontAwesomeIcon icon="cog" spin /></span>)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        )
    }

    render() {
        if (this.state.isChangePwd) {
            return this.renderPwdChange();
        }
        if (this.props.navbar) {
            return this.renderAsNavbar();
        } else {
            return this.renderAsForm();
        }
    }
}
export default withLocalize(withRouter(connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
)(LoginComponent)));
