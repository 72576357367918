import React from 'react'
import { withLocalize } from "react-localize-redux";
import connect from 'react-redux/lib/connect/connect';
import { isNullOrUndefined } from '../../store/storeFunctions';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { appContextActions } from '../../store/appContextStore'
import { toastMessage } from '../common/message/message';
import { parseQuery, hasPermission, redirectTo, apiPost } from '../../store/apiWrapper';
import Loading from '../common/loading/loading';
import ModalWindow from '../common/modal/modalWindow';
import Message from '../common/message/message';
import { url } from '../common/urlHelper';

class HistoryResultComponent extends React.Component {
    state = { showModal: false, comment: "", approvedNote: false, approvals: false, allInputFilesId: null, isCreatingFile: false }
    componentDidMount() {
        const qs = parseQuery(window.location.search);
        if (window.location.pathname === "/approvals") {
            if (hasPermission(null, "UserCCB")) {
                this.props.getNoApproved();
                this.setState({ approvals: true })
            }
            else {
                return;
            }
        }
        else if (window.location.pathname !== "/notAuthorized") {
            this.props.getHistory(qs.name, qs.ico, qs.ratingType, qs.ratingFrom, qs.ratingTo, qs.filterDateFrom, qs.filterDateTo, parseInt(qs.customerId, 10));
        }

    }

    getReportIds() {

        var reportIds = [];
        if (!isNullOrUndefined(this.props.search.searchResult.data)) {
            var muniSk = this.props.search.searchResult.data.filter(item => item.ratingType === "MunicipalitySK");
            reportIds = muniSk.map(element => element.id);
        }
        return reportIds;
    }

    createInputFiles() {
        var reportIds = this.getReportIds();
        this.setState({ isCreatingFile: true });
        apiPost(`api/history/create/all-input-files`, undefined, reportIds, res => {
            this.setState({ allInputFilesId: res.data, isCreatingFile: false })
            toastMessage(this.props.translate('IRating.History.AllInputFiles.Success'), "200");
        }, err => {
            toastMessage(this.props.translate('IRating.History.AllInputFiles.Failed'), "500");
            this.setState({ isCreatingFile: false });
        });
    }


    render() {
        if (window.location.pathname === "/approvals" && !hasPermission(null, "UserCCB")) {
            redirectTo(`/notAuthorized`);

        }
        return (
            <div className="columns work-container is-desktop">
                <div className="column left-navbar-container">
                    {(!isNullOrUndefined(this.props.search.searchResult) && !isNullOrUndefined(this.props.search.searchResult.data) && this.props.search.searchResult.data.some(item => item.ratingType === "MunicipalitySK")) &&
                        <div className="box">
                            <aside className="is-narrow-mobile has-text-centered is-fullheight">
                                <p className="menu-label">
                                    {this.props.translate('IRating.History.LeftMenu.Title')}
                                </p>
                                <ul className="menu-list">
                                    {(!isNullOrUndefined(this.props.search.searchResult) && !isNullOrUndefined(this.props.search.searchResult.data) && this.props.search.searchResult.data.some(item => item.ratingType === "MunicipalitySK")) &&
                                        <li><div className="has-text-centered is-primary">
                                            <p>{this.props.translate('IRating.Report.AllInputFiles')}</p>
                                            {this.state.allInputFilesId === null ? <button className="button is-fullwidth" disabled={this.state.isCreatingFile} onClick={() => this.createInputFiles()}>{this.props.translate('IRating.Report.AllInputFiles.Create')}</button>
                                                : <a className="button is-fullwidth" target="_blank" href={url.downloadAllInputFiles(this.state.allInputFilesId)}>{this.props.translate('IRating.Report.AllInputFiles.Download')}</a>
                                            }
                                        </div>
                                        </li>
                                    }
                                </ul>
                            </aside>
                        </div>
                    }
                </div>
                <div className="column right-body-container is-three-quarters">
                    {this.renderError(this.props.search)}
                    {this.renderResult(this.props.search)}
                    {this.renderLoading()}
                </div>
                <div className="column">
                </div>
            </div>
        )
    }

    renderLoading = () => {
        if (!this.props.search.isSearching)
            return null;
        return (<Loading />)
    }

    renderError = (sr) => {
        if (!isNullOrUndefined(sr) && !isNullOrUndefined(sr.error)) {
            return (<Message error={sr.error.data.error} />);
        }
        else if (!isNullOrUndefined(sr) && !isNullOrUndefined(sr.searchResult) && !isNullOrUndefined(sr.searchResult.error)) {
            return (<Message error={sr.searchResult.error} />);
        }
    }

    renderResult = (res) => {
        if (!isNullOrUndefined(res) && (!isNullOrUndefined(res.error) || (!isNullOrUndefined(res.searchResult) && !isNullOrUndefined(res.searchResult.error)))) {
            return null;
        }
        if (isNullOrUndefined(res) || isNullOrUndefined(res.searchResult) || isNullOrUndefined(res.searchResult.data))
            return null;
        return (
            <div className="box table-container">
                <ModalWindow isActive={this.state.showModal} title='IRating.ApprovalsNote'
                    onClickCancel={() => this.showHideModal()} cancelButtonName={'IRating.Common.Button.Close'}>
                    <label className="label">{this.props.translate(`IRating.Error.Message.${this.state.approvedNote ? "RatingApproved" : "RatingDisApproved"}`)}</label>
                    <br />
                    <div className="field">
                        <label>{this.props.translate('IRating.Note')}</label>
                        <div className="control">
                            <textarea value={this.state.comment} readOnly className="textarea" placeholder="Textarea"></textarea>
                        </div>
                    </div>
                </ModalWindow>
                <table className="table is-striped is-narrow is-hoverable is-fullwidth">
                    <tbody>
                        <tr>
                            <th>{this.props.translate('IRating.History.Ico')}</th>
                            <th>
                                {this.props.translate('IRating.History.Name')}
                            </th>
                            {hasPermission(null, "UserCCB") &&
                                <th>
                                    {this.props.translate('IRating.History.SubjectType')}
                                </th>
                            }
                            <th>
                                {this.props.translate('IRating.History.RatingType')}
                            </th>
                            <th>
                                {this.props.translate('IRating.History.RequestDate')}
                            </th>
                            {hasPermission(null, "UserCCB") &&
                                <th>
                                    {this.props.translate('IRating.History.CalculationDate')}
                                </th>
                            }
                            {hasPermission(null, "UserCCB") &&

                                <th>
                                    {this.props.translate('IRating.History.Customer')}
                                </th>
                            }
                            {(hasPermission(null, "UserCCB") || hasPermission(null, "UserRatingMSP")) &&

                                <th>
                                    {this.props.translate('IRating.History.User')}
                                </th>
                            }
                            {(!hasPermission(null, "UserRatingCalc") && !this.state.approvals) &&
                                <th>
                                    {this.props.translate('IRating.History.Status')}
                                </th>
                            }
                            {(hasPermission(null, "UserRatingMSP") || this.props.authentication.isGeneralUser || hasPermission(null, "PartnerView")) &&
                                <th>
                                    {this.props.translate('IRating.ProcessDate')}
                                </th>
                            }
                            {!hasPermission(null, "UserRatingMSP") &&
                                <React.Fragment>
                                    {!this.state.approvals &&
                                        <>
                                            {(hasPermission(null, "UserCCB") || hasPermission(null, "UserRatingCalc")) &&
                                                <th>
                                                    {this.props.translate('IRating.History.Monitored')}
                                                </th>
                                            }
                                            <th>
                                                {this.props.translate('IRating.History.Rating')}
                                            </th>
                                        </>
                                    }
                                    <th>
                                    </th>
                                </React.Fragment>
                            }
                        </tr>
                        {res.searchResult.data.map((r, i) =>

                            <tr key={i} onClick={() => { if (!isNullOrUndefined(r.comment) && r.comment != "") this.setModal(r.comment, r.status) }}>
                                <td>
                                    {r.ico}
                                </td>
                                <td>
                                    {r.name}
                                </td>
                                <td>
                                    {this.props.translate(`IRating.Report.${r.ratingType.toLowerCase()}`)}
                                </td>
                                {hasPermission(null, "UserCCB") &&
                                    <td>
                                        {this.props.translate(`IRating.Report.${r.subjectType.toLowerCase()}`)}
                                    </td>
                                }
                                <td>
                                    {moment(r.dateRequest).format('L')}
                                </td>
                                {hasPermission(null, "UserCCB") &&
                                    <td>
                                        {moment(r.dateCalculation).format('L')}
                                    </td>
                                }
                                {hasPermission(null, "UserCCB") &&

                                    <td>
                                        {r.customer}
                                    </td>
                                }
                                {(hasPermission(null, "UserCCB") || hasPermission(null, "UserRatingMSP")) &&

                                    <td>
                                        {r.user}
                                    </td>
                                }
                                {(!hasPermission(null, "UserRatingCalc") && !this.state.approvals) &&
                                    <td>
                                        {r.status}
                                    </td>
                                }
                                {(hasPermission(null, "UserRatingMSP") || this.props.authentication.isGeneralUser || hasPermission(null, "PartnerView")) &&
                                    <td>
                                        {!isNullOrUndefined(r.processDate) &&
                                            moment(r.processDate).format('L')}
                                    </td>
                                }
                                {!hasPermission(null, "UserRatingMSP") &&
                                    <React.Fragment>
                                        {!this.state.approvals &&
                                            <>
                                                {(hasPermission(null, "UserCCB") || hasPermission(null, "UserRatingCalc")) &&
                                                    <td>
                                                        {r.monitored && <FontAwesomeIcon icon="check" />}
                                                    </td>
                                                }
                                                <td>
                                                    {r.status === this.props.translate(`IRating.History.Status.Approved`) ? r.rating : ""}
                                                </td>
                                            </>
                                        }
                                        <td>
                                            {(this.state.approvals || r.status === this.props.translate(`IRating.History.Status.Approved`)) &&
                                                <Link className="button" to={`${this.state.approvals ? window.location.pathname : "/report"}/result?id=${r.id}`}>{this.props.translate('IRating.History.Show')}</Link>
                                            }
                                        </td>
                                    </React.Fragment>
                                }
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

        );
    }

    showHideModal = () => {
        this.setState({ showModal: (this.state.showModal === false) });
    }
    setModal = (comment, state) => {
        this.setState({ comment: comment, approvedNote: state === this.props.translate(`IRating.History.Status.Approved`) ? true : false });
        this.showHideModal()
    }
}

export default withLocalize(connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
)(HistoryResultComponent));