import React from 'react';
import { connect } from 'react-redux';
import { getLanguage } from '../../store/apiWrapper';
import { isNullOrUndefined } from '../../store/storeFunctions';
import { Translate } from "react-localize-redux";
import { withLocalize } from "react-localize-redux";
import { bindActionCreators } from 'redux';
import { appContextActions } from '../../store/appContextStore'
import { withRouter } from 'react-router';
import classnames from 'classnames';
import moment from "moment";

import './personalData.scss';

const personalDataPages = [
    { path: "/irating", translateKey: "IRating" },
    { path: "/gdpr", translateKey: "GDPR" },
    { path: "/cookies", translateKey: "Cookies" },
]

class PersonalData extends React.Component {
    render() {
        const page = personalDataPages.find(page => `/personal-data${page.path}` === this.props.location.pathname);

        if (isNullOrUndefined(page))
            return null;

        var agentLanguage = "";
        if (!isNullOrUndefined(this.props.user) &&
            !isNullOrUndefined(this.props.user.authentication) &&
            !isNullOrUndefined(this.props.user.authentication.profile) &&
            !isNullOrUndefined(this.props.user.authentication.profile.agentLanguage))
            agentLanguage = this.props.user.authentication.profile.agentLanguage;
        else
            agentLanguage = window.location.hostname.includes("cz") ? "cs-CZ" : "sk-SK";

        var country = agentLanguage === 'sk-SK' ? 'SK' : 'CZ';

        return (
            <div className='content-root'>
                <div className="card personal-data card-special">
                    <div className="card-body">
                        <div className="rating-update-info">
                            <Translate id="PersonalData.Date" data={{ 0: moment(new Date(2023, 0, 20)).format("l") }}></Translate>
                        </div>
                        <div className={classnames('personalData-text', { 'is-normal': localStorage.locales_url_format === 'normal' })}>
                            <Translate id={`IRating.PersonalData.${page.translateKey}.Content.${country}`} options={{ renderInnerHtml: true }} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withLocalize(connect(
    state => ({user: state.appContext}),
    dispatch => bindActionCreators(appContextActions, dispatch)
)(withRouter(PersonalData)));